import { mutateGraphQL, queryGraphQL } from '@/data';
import { InvoiceSyncLineItemsRead } from '@/pages/dashboard/commerce/invoices/invoiceGQL';
import { MutationCommercesWriteArgs, Order, QueryLineItemsReadArgs } from '@/types/schema';
import { gql } from '@apollo/client';
import axios from 'axios';
import { isEmpty } from 'lodash-es';

export default async function SyncToClover( data: Order, sendNote?: boolean ) {
	if ( data.companyLocation?.gateway?.external && data.companyLocation?.gateway?.external !== 'CLOVER' && !data.externalId ) return data;
	
	const { data: cloverCommerce } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postOrder`, {
		id: data.id,
		sendNote,
	} );
	return cloverCommerce;
	
}

export const syncToCloverAfterValidation = async ( invoicesToSync: Order[], hideOnClover?: boolean ) => {
	if ( isEmpty( invoicesToSync ) ) return;
	const invoices = invoicesToSync.filter( ( invoice ) => !( !invoice.externalId && invoice.paidTotal > 0 ) );
	
	if ( hideOnClover !== undefined ) {
		await mutateGraphQL<MutationCommercesWriteArgs>(
			{
				mutation : gql`mutation CommercesWrite_d33c($ids: [String!], $inputs: [OrderValidator!], $options: FilterOptions, $remove: Boolean) {
					commercesWrite(ids: $ids, inputs: $inputs, options: $options, remove: $remove)
				}`,
				variables: {
					inputs: invoices.map( ( commerce ) => ( {
						id      : commerce.id,
						metadata: { hideOnClover },
					} ) ),
				},
			},
		);
	}
	
	for ( const invoice of invoices ) {
		
		const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
			query    : InvoiceSyncLineItemsRead,
			variables: { options: { limit: 1000, filter: { order: invoice.id } } },
		} );
		
		if ( isEmpty( lineItemsRead?.items ) ) {
			continue;
		}
		
		const lineItemDollarFee = lineItemsRead.items.map( ( lineItem ) => lineItem.prices?.find( ( price ) => price.value > 0 && !price.metadata?.externalTax ) );
		if ( !isEmpty( lineItemDollarFee.filter( Boolean ) ) ) {
			continue;
		}
		
		const orderDollarFee = invoice.prices?.find( ( price ) => !price.isPercent && price.value > 0 && !price.metadata?.cloverTaxPercent );
		if ( !isEmpty( orderDollarFee ) ) {
			continue;
		}
		
		const lineItemWithMoreDescription = lineItemsRead.items.find( ( lineItem ) => lineItem.description?.length > 255 );
		if ( lineItemWithMoreDescription ) {
			continue;
		}
		
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postOrder`, { id: invoice.id } );
	}
	
};
