import { gql } from '@apollo/client';

export const InvoiceSyncAddressWrite = gql`
	mutation AddressWrite_d48f($id: String, $clientId: String, $input: AddressValidator, $remove: Boolean, $method: String) {
		addressWrite(id: $id, clientId: $clientId, input: $input, remove: $remove, method: $method) {
			id
		}
	}
`;

export const InvoiceSyncAddressesRead = gql`
	query AddressesRead_236e($clientId: String, $options: FilterOptions) {
		addressesRead(clientId: $clientId, options: $options) {
			items {
				id
			}
			count
		}
	}
`;

export const InvoiceGQLWrite = gql`
	mutation InvoiceWrite_a30c($id: String, $externalId: String,$externalValue: String$method: String, $customNumber: Boolean, $input: OrderValidator, $remove: Boolean) {
		invoiceWrite(id: $id, externalId: $externalId,externalValue: $externalValue, method: $method, customNumber: $customNumber, input: $input, remove: $remove) {
			id
		}
	}
`;

export const InvoiceSyncLineItemsRead = gql`
	query LineItemsRead_e524($options: FilterOptions) {
		lineItemsRead(options: $options) {
			items {
				id
				name
				price
				quantity
				description
				prices {
					value
					metadata
				}
			}
			count
		}
	}
`;

export const InvoiceCreateAgreementsRead = gql`
	query AgreementsRead_4a68($options: FilterOptions) {
		agreementsRead(options: $options) {
			items {
				id
				title
				requireSignature
				body
				expiration
			}
		}
	}
`;

export const CommerceGQLWrite = gql`
	mutation CommerceWrite_fbc2($id: String, $externalId: String,$externalValue: String$method: String, $customNumber: Boolean, $input: OrderValidator, $remove: Boolean) {
		commerceWrite(id: $id, externalId: $externalId,externalValue: $externalValue, method: $method, customNumber: $customNumber, input: $input, remove: $remove) {
			id
		}
	}
`;

export const InvoiceLineItemsReadGQL = gql`query LineItemsRead_88f6($options: FilterOptions) {
	lineItemsRead(options: $options) {
		items {
			id
			name
			unit
			quantity
			price
			status
			prices {
				id
				metadata
				isPercent
				value
			}
		}
		count
	}
}`;
